import React from 'react';

function PrivacyPolicy() {
  return (
    <main className="flex-grow pt-24 pb-16">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-extrabold text-gray-900 mb-8 flex items-center justify-center">Política de Privacidad</h1>
        <div className="prose prose-lg">
          <h2>Introducción</h2>
          <p>
            Este documento explica cómo DRW recopila, procesa, almacena y/o comparte
            datos personales y/o información de los usuarios (en adelante,
            "Información"). Al acceder y/o utilizar los Servicios, usted consiente
            la recopilación, transferencia, manipulación, almacenamiento,
            divulgación y otros usos de su información, tal como se describe en esta
            Política de Privacidad.
          </p>
          
          <h2>La Información que se recopila</h2>
          <p>
            DRW puede recopilar y procesar los siguientes datos sobre usted:
          </p>
          <ul>
            <li>Información que usted proporciona al completar formularios al
                acceder y/o utilizar los Servicios, o al crear una cuenta dentro
                de los Servicios;</li>
            <li>Detalles de su uso de los Servicios y los recursos a los que accede;</li>
            <li>La correspondencia entre usted y DRW y cualquier interacción, o con
                otros usuarios en el Servicio, los canales y/o cualquier red
                social relacionada;</li>
            <li>De terceros que poseen datos sobre usted y que aceptan compartir
                dichos datos con nosotros;</li>
            <li>Información recopilada a través de cookies y otras tecnologías
                similares y/o otros mecanismos, como se explica más adelante.</li>
          </ul>
          
          <h2>I. Cookies</h2>
          
          <p>
            DRW utiliza cookies y otras tecnologías similares en los Servicios. Estas tecnologías operan colocando un pequeño archivo que almacena información en su computadora o dispositivo móvil; y/o accediendo a la información en su dispositivo. DRW utiliza cookies y tecnologías similares para reconocerlo a usted y su dispositivo, por ejemplo, identificando su dirección IP; para permitir que los Servicios interactúen con una red social o plataforma de terceros donde usted ha decidido permitir dicha interacción; para permitir procesos de pago cuando envía instrucciones de pago; para habilitar a DRW y a terceros a brindarle servicios más personalizados; y para recopilar datos como el modelo de su dispositivo, sistema operativo y tamaño de pantalla, otras aplicaciones instaladas en su dispositivo e información sobre cómo utiliza los Servicios. Al acceder y/o utilizar los Servicios, usted consiente el uso de cookies y tecnologías similares de acuerdo con esta Política de Privacidad. Puede deshabilitar las cookies a través de la configuración de su navegador web o dispositivo móvil, pero algunas funciones de los Servicios pueden no funcionar correctamente. Alternativamente, si no desea dicha recopilación de datos como se describe en esta sección, debería dejar de utilizar los Servicios.
          </p>
          <h2>II. Publicidad</h2>
          <p>
          Los anunciantes en dispositivos móviles a veces utilizan identificadores publicitarios para habilitar y optimizar su publicidad. Estos identificadores son no permanentes, no personales, y específicos del dispositivo. DRW puede utilizar el identificador publicitario de su dispositivo y otra información asociada para ofrecer anuncios relacionados con sus intereses y para mejorar y medir la efectividad de las campañas publicitarias.
          </p>
          
          <h2>III. Información de ubicación</h2>
          <p>
          Puede elegir publicar su ubicación en su perfil de DRW. También puede compartir su ubicación cuando habilita su dispositivo para enviar dicha información de ubicación. DRW puede utilizar y almacenar información sobre su ubicación para proporcionar funciones especiales y/o mejorar y personalizar los Servicios.
          </p>

          <h2>IV. Enlaces</h2>
          <p>
          DRW puede realizar un seguimiento de cómo interactúa con enlaces en los Servicios, incluidos notificaciones por correo electrónico, servicios de terceros y aplicaciones de clientes, redirigiendo clics o mediante otros medios
          </p>

          <h2>V. Datos de registro</h2>

          <p>
          Los servidores registran automáticamente la información creada por su uso de los Servicios. Estos datos pueden incluir información como su dirección IP, tipo de navegador, sistema operativo, la página web de referencia, páginas visitadas, ubicación, su operador móvil, identificadores de dispositivo y aplicación, términos de búsqueda e información de cookies. Los datos de registro se reciben cuando interactúa con los Servicios. Estos datos se utilizan para proporcionar los Servicios y para medir, personalizar y mejorar los mismos.
          </p>

          <h2>VI. Información de pago</h2>
          <p>
          Si realiza una compra en los Servicios, DRW puede recopilar la información de facturación y financiera necesaria para procesar los cargos; y/o hacerlo en nombre de los proveedores de servicios de pago correspondientes. Las compras de servicios de terceros están sujetas a las políticas aplicables a dichos proveedores.
          </p>

          <h2>VII. Servicios de terceros</h2>
          <p>
          DRW utiliza una variedad de servicios de terceros para ayudar en la prestación de los Servicios, como servicios de alojamiento y otros servicios. Estos proveedores de servicios de terceros pueden recopilar información enviada por su navegador como parte de una solicitud de página web, como cookies o su dirección IP. Además, los socios publicitarios de terceros pueden compartir información para medir la calidad de los anuncios y personalizar los anuncios, por ejemplo, para mostrar anuncios sobre cosas en las que ya ha mostrado interés.
          </p>

          <h2>VIII. Correspondencia con el soporte al cliente</h2>
          <p>
          Cuando solicita asistencia al soporte al cliente de DRW, se recopilará la información de contacto que proporcione, así como información sobre su juego o actividad en el Servicio, su número de identificación de usuario, y la correspondencia y cualquier información contenida en la misma. Si está disponible a través de los Servicios, puede proporcionar a DRW información de perfil para hacer pública, como una breve biografía, ubicación, sitio web, número de teléfono, una foto, información para personalizar su cuenta, etc. Dicha información de contacto puede utilizarse para enviarle información sobre los Servicios o información relacionada. Puede utilizar la configuración de su cuenta para darse de baja de las notificaciones de DRW y/o de otros usuarios y encontrar usuarios que conoce. También puede darse de baja siguiendo las instrucciones contenidas en la notificación o las instrucciones en el Servicio. Además, DRW puede utilizar su información de contacto para ayudar a otros a encontrar su cuenta, incluso a través de servicios de terceros y aplicaciones de clientes. Proporcionar cualquier información adicional descrita en esta sección es completamente opcional.
          </p>

          <h2>Uso de la Información</h2>
          <p>
          Cuando crea o configura una cuenta en los Servicios, proporciona información personal, como su nombre, nombre de usuario, contraseña y dirección de correo electrónico. Parte de esta información, por ejemplo, su nombre y/o nombre de usuario, puede aparecer públicamente en los Servicios, incluida su página de perfil y en los resultados de búsqueda. Usted acepta que DRW y/o terceros en su nombre y/o sus socios puedan utilizar la Información con el propósito de contactarlo como parte del soporte al cliente; enviarle actualizaciones o información sobre los Servicios; gestionar su cuenta y relación con el Servicio y mejorar su experiencia cuando lo utilice; mejorar los Servicios; realizar investigaciones, encuestas y participar con usted, por ejemplo, enviándole comunicaciones para estos fines; marketing y promoción de los Servicios o productos; personalizar y optimizar los Servicios, contenido promocional y/o publicitario; y crear informes, análisis o servicios similares con fines de investigación o inteligencia empresarial.
          </p>

          <h2>Compartir y divulgar información</h2>
          <p>
          DRW solo compartirá sus datos con terceros con su consentimiento (incluido según lo establecido en esta Política de Privacidad), cuando sea razonablemente necesario para proporcionar los Servicios, por ejemplo, proporcionando Información a proveedores que DRW puede utilizar para cumplir con los Servicios; cuando sea necesario para llevar a cabo sus instrucciones, por ejemplo, para procesar una instrucción de pago, su Información debe ser proporcionada a los procesadores de pago; cuando sus datos sean anónimos y agregados, es decir, que no se le pueda identificar personalmente a partir de ellos; cuando usted envíe información como parte de una competencia o de otra manera interactúe con cualquier canal o red social, la información que usted envíe puede ser publicada; cuando se crea razonablemente que está permitido por la ley o reglamento; para cumplir con cualquier obligación legal, o para hacer cumplir o aplicar los Términos, esta Política de Privacidad y/o cualquier otro acuerdo con usted; o para proteger los derechos y/o la propiedad de DRW o los derechos y/o la propiedad de terceros. DRW puede compartir o divulgar su información no privada, como información de perfil de usuario pública, mensajes públicos, correo electrónico, etc., o compartir o divulgar su información de manera anónima o agregada de una manera que no permita su identificación personal.
          </p>

          <h2>Interacción con redes sociales y/o plataformas</h2>
          <p>
          Puede permitir que los Servicios interactúen con cualquier red social y/o plataforma de terceros, como Facebook, que proporcionará datos sobre usted a DRW. Dado que cualquier otra aplicación o sitio web diferente a los Servicios es propiedad de un tercero, debe asegurarse de leer sus términos de servicio y las políticas de privacidad aplicables. Usted entiende que cuando permite que los Servicios interactúen con cualquier red social y/o plataforma de terceros, DRW puede compartir datos sobre usted con sus contactos y otros usuarios de los Servicios, y viceversa. Estos datos pueden incluir su nombre, foto de perfil, estado de actividad e información relacionada con su uso de los Servicios. Puede cambiar esto ajustando su configuración con ese proveedor de terceros.
          </p>

          <h2>Plazo</h2>
          <p>
          DRW puede conservar la información durante el tiempo que sea necesario para cumplir con los fines para los que fue recopilada o según sea necesario para proporcionar los Servicios, incluso después de que haya discontinuado o eliminado cualquier cuenta, o después del fin de la prestación de los Servicios, si la retención de dicha información es razonablemente necesaria para cumplir con obligaciones legales, cumplir con requisitos regulatorios, resolver disputas entre usuarios, prevenir fraudes, o cualquier otro uso.
          </p>

          <h2>Cookies</h2>
          <p>
          Al igual que muchos sitios web, utilizamos cookies y tecnologías similares para recopilar datos adicionales sobre el uso del sitio web y mejorar nuestros Servicios, pero las cookies no son necesarias para muchas partes de nuestros Servicios. Una cookie es un pequeño archivo de datos que se transfiere al disco duro de su computadora. Podemos utilizar tanto cookies de sesión como cookies persistentes para comprender mejor cómo interactúa con nuestros Servicios, monitorear el uso agregado por parte de nuestros usuarios y la ruta del tráfico web en nuestros Servicios, y personalizar y mejorar nuestros Servicios. La mayoría de los navegadores de Internet aceptan automáticamente las cookies. Puede cambiar la configuración de su navegador para dejar de aceptar cookies o para que le notifique antes de aceptar una cookie de los sitios web que visita. Sin embargo, es posible que algunos Servicios no funcionen correctamente si desactiva las cookies.
          </p>

          <h2>Protección de la Información</h2>
          <p>
          DRW mantiene las salvaguardas técnicas y físicas adecuadas para proteger la Información contra la destrucción o pérdida accidental o ilegal, la alteración, la divulgación o el acceso no autorizado, y cualquier otra forma ilegal de procesamiento de los datos en su posesión. Sin embargo, DRW no garantiza que la información no será accedida, divulgada, alterada o destruida por incumplimiento de cualquiera de las salvaguardas mencionadas.
La información puede ser transferida y/o almacenada en destinos de todo el mundo. DRW toma todas las medidas razonablemente necesarias para asegurar que la información sea tratada de manera segura y de acuerdo con esta Política de Privacidad. En caso de que DRW se vea involucrado en una quiebra, fusión, adquisición, reorganización o venta de activos, su información puede ser vendida o transferida como parte de esa transacción. Los compromisos en esta Política de Privacidad se aplicarán a la Información transferida a la nueva entidad. En los Servicios, puede encontrar enlaces a sitios web de terceros. Usted entiende que al hacer clic en estos enlaces, cualquier dato que proporcione después está sujeto a la política de privacidad de ese tercero y no a la de DRW. Por lo tanto, DRW no asume ninguna responsabilidad por el contenido, la seguridad o la protección de ningún sitio web de terceros. Los Servicios no están dirigidos a personas menores de edad. DRW no recopila deliberadamente ninguna información de niños menores de edad. Si usted toma conocimiento de que un niño menor de edad ha proporcionado información personal, se tomarán medidas para eliminar dicha información y terminar esa cuenta.
Si usted toma conocimiento de que algún menor ha proporcionado información personal sin el consentimiento esencial del tutor legal, por favor contacte a servicio@drw.com.ar.
Independientemente del país en el que resida o desde el que suministre información, usted autoriza a DRW a almacenar y/o utilizar la Información de acuerdo con esta Política de Privacidad en cualquier país donde DRW pueda operar. A pesar de cualquier disposición en contrario en esta Política, DRW puede conservar o divulgar su información en la medida en que sea razonablemente necesario para cumplir con una ley, regulación o solicitud legal; para proteger la seguridad de cualquier persona; para abordar fraudes, problemas de seguridad o técnicos; o para proteger los derechos o la propiedad de DRW. Sin embargo, nada en esta Política de Privacidad está destinado a limitar cualquier defensa legal o objeción que usted pueda tener frente a una solicitud de divulgación de información por parte de un tercero, incluido el gobierno. DRW desea asegurarse de que su información sea precisa y esté actualizada. Usted puede solicitar la modificación, corrección o eliminación de la información con las herramientas y configuraciones de cuenta del Servicio, o contactando a DRW en servicio@drw.com.ar.
La información que ha compartido a través de los Servicios en cualquier red social o plataforma de terceros puede no ser modificada o eliminada cuando está bajo el control de una red y/o plataforma de terceros. Si no desea que su información sea utilizada con fines de marketing, puede cambiar sus preferencias de marketing, darse de baja o, si tiene alguna pregunta o comentario sobre esta Política de Privacidad, por favor contacte a DRW: servicio@drw.com.ar.
Si algún tribunal u otra autoridad competente determina que alguna parte de esta Política de Privacidad es inválida o inaplicable, los demás términos de esta Política de Privacidad no se verán afectados.
Esta Política de Privacidad se rige e interpreta de acuerdo con las leyes de Uruguay. Cualquier disputa que surja en relación con esta Política de Privacidad estará sujeta a la jurisdicción exclusiva de los tribunales ubicados en la ciudad de Montevideo – Uruguay. Usted consiente a la jurisdicción y sede en dichos tribunales y renuncia a cualquier objeción. DRW puede revisar o enmendar esta Política de Privacidad de vez en cuando. La versión más reciente de la política regirá el uso de la Información y estará disponible en https://www.drw.com.ar/privacy/ DRW puede notificar los cambios en la Política de Privacidad por correo electrónico a la dirección asociada con su cuenta. Si usted objeta alguno de los cambios, debe dejar de usar inmediatamente los Servicios y cerrar cualquier cuenta relacionada. Al continuar accediendo o utilizando los Servicios después de que los cambios entren en vigencia, usted acepta estar sujeto a la Política de Privacidad revisada.
          </p>
          {/* Continue adding the rest of the privacy policy content here */}
          
        </div>
      </div>
    </main>
  );
}

export default PrivacyPolicy;