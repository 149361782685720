import React, { useState, useEffect } from 'react';
import { Route, Routes, Link, useLocation , useNavigate } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import PrivacyPolicy from './PrivacyPolicy';
import TermsAndConditions from './TermsAndConditions';
import logo1 from './assets/matteucci.png';
import logo2 from './assets/asistente.png';
import logo3 from './assets/azurra.png';
import logo4 from './assets/baguala.png';
import logo5 from './assets/deppto.svg';
import logo6 from './assets/dnazyme.png';
import logo7 from './assets/ibis.png';
import logo8 from './assets/planika.png';
import logo9 from './assets/form.png';

function Modal({ isOpen, onClose, children }) {
  if (!isOpen) return null;

  return (
    <div className="fixed z-50 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" onClick={onClose}></div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            {children}
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" onClick={onClose}>
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({ name: '', email: '', message: '', phone: '' });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsModalOpen(true);
    setFormData({ name: '', email: '', message: '', phone: '' });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (location.pathname !== '/') return; // Only run on main page
      const scrollPosition = window.pageYOffset;
      const sections = document.querySelectorAll('section');
      sections.forEach((section) => {
        const sectionTop = section.offsetTop - 100;
        const sectionBottom = sectionTop + section.offsetHeight;
        if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
          const id = section.getAttribute('id');
          document.querySelectorAll('.nav-link').forEach((link) => {
            link.classList.remove('text-indigo-600');
            if (link.getAttribute('href') === `#${id}`) {
              link.classList.add('text-indigo-600');
            }
          });
        }
      });
    };

    const handleLinkClick = (e) => {
      const href = e.currentTarget.getAttribute('href');
      if (href.startsWith('#')) {
        e.preventDefault();
        if (location.pathname !== '/') {
          // If not on main page, navigate to main page first
          navigate('/');
          // Use setTimeout to wait for navigation to complete
          setTimeout(() => {
            const target = document.querySelector(href);
            if (target) {
              const headerOffset = 64;
              const elementPosition = target.getBoundingClientRect().top;
              const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
              window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
              });
            }
          }, 100);
        } else {
          // If already on main page, just scroll
          const target = document.querySelector(href);
          if (target) {
            const headerOffset = 64;
            const elementPosition = target.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
            window.scrollTo({
              top: offsetPosition,
              behavior: "smooth"
            });
          }
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    document.querySelectorAll('.nav-link').forEach(link => {
      link.addEventListener('click', handleLinkClick);
    });

    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.querySelectorAll('.nav-link').forEach(link => {
        link.removeEventListener('click', handleLinkClick);
      });
    };
  }, [location, navigate]);

  const settings = {
    autoplay: true,
    autoplaySpeed: 0,
    speed: 3000,
    arrows: false,
    swipe: false,
    slidesToShow: 4,
    cssEase: 'linear',
    pauseOnFocus: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="bg-white" style={{
      backgroundImage: `
        linear-gradient(to right, #f0f0f0 1px, transparent 1px),
        linear-gradient(to bottom, #f0f0f0 1px, transparent 1px)
      `,
      backgroundSize: '80px 80px',
      position: 'relative',
    }}>
      <ScrollToTop />
      <div className="absolute inset-0" style={{
        boxShadow: 'inset 0 0 80px rgba(0,0,0,0.1)',
      }}></div>
      <div className="relative flex flex-col min-h-screen">
        <header className="fixed w-full bg-white z-10 shadow">
          <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between h-16">
              <div className="flex-shrink-0">
                <Link to="/">
                  <img className="h-8 w-15" src="/logo.svg" alt="DRW Consulting" />
                </Link>
              </div>
              <div className="hidden md:block flex-grow">
                <div className="flex justify-center space-x-4">
                  <a href="#inicio" className="nav-link text-gray-600 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium">Inicio</a>
                  <a href="#nosotros" className="nav-link text-gray-600 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium">Sobre Nosotros</a>
                  <a href="#clientes" className="nav-link text-gray-600 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium">Clientes</a>
                  <a href="#trabaja" className="nav-link text-gray-600 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium">Trabaja con Nosotros</a>
                </div>
              </div>
              <div className="-mr-2 flex md:hidden">
                <button
                  onClick={() => setIsOpen(!isOpen)}
                  type="button"
                  className="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  aria-controls="mobile-menu"
                  aria-expanded="false"
                >
                  <span className="sr-only">Open main menu</span>
                  {!isOpen ? (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </button>
              </div>
            </div>
          </nav>

          <Transition
            show={isOpen}
            enter="transition ease-out duration-100 transform"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-75 transform"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            {(ref) => (
              <div className="md:hidden" id="mobile-menu">
                <div ref={ref} className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                  <a href="#inicio" className="nav-link text-gray-600 hover:text-gray-900 block px-3 py-2 rounded-md text-base font-medium">Inicio</a>
                  <a href="#nosotros" className="nav-link text-gray-600 hover:text-gray-900 block px-3 py-2 rounded-md text-base font-medium">Sobre Nosotros</a>
                  <a href="#clientes" className="nav-link text-gray-600 hover:text-gray-900 block px-3 py-2 rounded-md text-base font-medium">Clientes</a>
                  <a href="#trabaja" className="nav-link text-gray-600 hover:text-gray-900 block px-3 py-2 rounded-md text-base font-medium">Trabaja con Nosotros</a>
                </div>
              </div>
            )}
          </Transition>
        </header>

        <Routes>
          <Route path="/" element={
            <main className="flex-grow pt-16">
              <section id="inicio" className="min-h-screen flex items-center justify-center">
                <div className="text-center">
                  <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                    <span className="block">Bienvenidos a DRW</span>
                    <span className="block text-indigo-600">Innovación y Calidad</span>
                  </h1>
                  <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                    Somos una empresa dedicada a ofrecer soluciones innovadoras y de alta calidad para nuestros clientes.
                  </p>
                </div>
              </section>

              <section id="nosotros" className="min-h-screen flex items-center justify-center bg-white bg-opacity-70">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                  <h2 className="text-4xl font-extrabold text-gray-900 text-center mb-8">Sobre Nosotros</h2>
                  <p className="mt-4 text-lg text-gray-500 text-center max-w-3xl mx-auto">
                    En DRW, nos comprometemos a ofrecer soluciones de alta calidad y servicios excepcionales a nuestros clientes. Nuestra pasión por la innovación y la excelencia nos impulsa a superar las expectativas en cada proyecto.
                  </p>
                </div>
              </section>

              <section id="clientes" className="min-h-screen flex flex-col items-center justify-center">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-16">
                  <h2 className="text-4xl font-extrabold text-gray-900 text-center mb-8">Nuestros Clientes</h2>
                  <p className="mt-4 text-lg text-gray-500 text-center max-w-3xl mx-auto">
                    En DRW, contamos con la confianza de una amplia variedad de clientes que han experimentado la transformación de sus negocios gracias a nuestras soluciones.
                    Desde pequeñas startups hasta grandes empresas consolidadas, todos ellos han encontrado en nosotros un aliado estratégico que les permite enfocarse en lo que realmente importa: hacer crecer su negocio.
                  </p>
                </div>
                <div className="w-full py-12">
                  <Slider {...settings}>
                    <div><img src={logo1} alt="Client 1" className="h-16 mx-auto" /></div>
                    <div><img src={logo3} alt="Client 3" className="h-16 mx-auto" /></div>
                    <div><img src={logo2} alt="Client 2" className="h-16 mx-auto" /></div>
                    <div><img src={logo4} alt="Client 4" className="h-16 mx-auto" /></div>
                    <div><img src={logo5} alt="Client 5" className="h-16 mx-auto" /></div>
                    <div><img src={logo6} alt="Client 6" className="h-16 mx-auto" /></div>
                    <div><img src={logo8} alt="Client 8" className="h-16 mx-auto" /></div>
                    <div><img src={logo7} alt="Client 7" className="h-16 mx-auto" /></div>
                  </Slider>
                </div>
              </section>

              <section id="trabaja" className="min-h-[calc(100vh-64px)] flex items-center justify-center bg-white bg-opacity-70">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 w-full">
                  <h2 className="text-4xl font-extrabold text-gray-900 text-center mb-8">Trabaja con Nosotros</h2>
                  <div className="flex flex-col md:flex-row items-center justify-between">
                    <form onSubmit={handleSubmit} className="mt-8 space-y-6 w-full md:w-1/2 max-w-lg bg-white p-8 rounded-lg shadow-md">
                      <div className="space-y-4">
                        <div>
                          <label htmlFor="name" className="sr-only">Nombre</label>
                          <input
                            id="name"
                            name="name"
                            type="text"
                            required
                            className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm bg-gray-50"
                            placeholder="Nombre"
                            value={formData.name}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div>
                          <label htmlFor="email" className="sr-only">Email</label>
                          <input
                            id="email"
                            name="email"
                            type="email"
                            required
                            className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm bg-gray-50"
                            placeholder="Email"
                            value={formData.email}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div>
                          <label htmlFor="phone" className="sr-only">Telefono</label>
                          <input
                            id="phone"
                            name="phone"
                            type="phone"
                            required
                            className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm bg-gray-50"
                            placeholder="Telefono"
                            value={formData.phone}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div>
                          <label htmlFor="message" className="sr-only">Mensaje</label>
                          <textarea
                            id="message"
                            name="message"
                            required
                            className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm bg-gray-50 resize-none"
                            placeholder="Contanos tu proyecto"
                            rows="4"
                            value={formData.message}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div>
                        <button
                          type="submit"
                          className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Enviar
                        </button>
                      </div>
                    </form>
                    <div className="w-full md:w-1/2 mt-8 md:mt-0 md:pl-8">
                      <img src={logo9} alt="Descriptive text"  />
                    </div>
                  </div>
                </div>
              </section>
            </main>
          } />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        </Routes>

        <footer className="bg-white bg-opacity-70 mt-auto py-8">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col md:flex-row justify-between items-center">
              <div className="flex space-x-6 mb-4 md:mb-0">
                <a href="https://web.facebook.com/profile.php?id=61559586864615" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-gray-500">
                  <FaFacebook className="h-6 w-6" />
                </a>
                <a href="https://www.instagram.com/drw.consulting/" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-gray-500">
                  <FaInstagram className="h-6 w-6" />
                </a>
                <a href="https://www.linkedin.com/in/administraci%C3%B3n-drw-8055222a9/" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-gray-500">
                  <FaLinkedin className="h-6 w-6" />
                </a>
              </div>
              <div className="text-center md:text-right">
                <p className="text-gray-400">
                  &copy; 2023 - All rights reserved. 
                  <Link to="/privacy-policy" className="ml-2 text-indigo-600 hover:text-indigo-500">
                    Privacy Policy -
                  </Link>
                  <Link to="/terms-and-conditions" className="ml-2 text-indigo-600 hover:text-indigo-500">
                  Terms and Conditions 
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </footer>
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
              Gracias por elegirnos!
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                A la brevedad nos estaremos comunicando.
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default App;