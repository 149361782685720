import React from 'react';

function TermsAndConditions() {
  return (
    <main className="flex-grow pt-24 pb-16">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-extrabold text-gray-900 mb-8 flex items-center justify-center">Términos y Condiciones</h1>
        <div className="prose prose-lg">
        <h2>TÉRMINOS Y CONDICIONES ONLINE DE DE ROSE & WHITE S.A.S</h2>

            <p>Este Acuerdo es celebrado entre la Compañía o Usuario representado por el usuario identificado con el correo electrónico utilizado para iniciar sesión, (la "PARTE CONTRATANTE") y DE ROSE & WHITE S.A.S., una Sociedad por Acciones Simplificada legalmente constituida bajo las leyes de Córdoba, Argentina, (el "CONTRATISTA"), de acuerdo con los siguientes términos y condiciones (los "Términos y Condiciones") que rigen la concesión de la licencia de la plataforma Bot por parte de la PARTE CONTRATANTE a favor del CONTRATISTA.</p>

            <p>El CONTRATISTA es el propietario exclusivo de la licencia maestra de una plataforma de bots y sus componentes (la “Plataforma Tecnológica” y/o el “Software”).</p>

            <h3>2. TÉRMINOS GENERALES</h3>

            <h3>2.1. Consentimiento</h3>

            <p>La PARTE CONTRATANTE acepta y reconoce que la sublicencia de la Plataforma Tecnológica implica la comprensión, aceptación y aprobación automática de estos Términos y Condiciones. Si la PARTE CONTRATANTE no está de acuerdo con alguno de los términos aquí expuestos, la PARTE CONTRATANTE puede rescindir estos Términos y Condiciones según lo indicado a continuación.</p>

            <h3>2.2. Sublicencia</h3>

            <p>El CONTRATISTA otorga a la PARTE CONTRATANTE la sublicencia para utilizar la Plataforma Tecnológica conforme a los términos indicados en el presente y cualquier otro indicado en la opción seleccionada al momento del registro, destinada a la construcción de bots de DE ROSE & WHITE S.A.S (la "Plataforma Tecnológica" y/o "Software"), los cuales han sido creados, desarrollados y son propiedad de DE ROSE & WHITE S.A.S, según lo mencionado anteriormente (la "Sublicencia").</p>

            <h3>2.3. Prueba</h3>

            <p>Al momento del registro, si la PARTE CONTRATANTE selecciona la opción "plan de prueba gratuito", el CONTRATISTA otorgará el uso de la Plataforma Tecnológica durante el período de prueba, según lo establecido en la Sección 3 del presente documento.</p>

            <h3>2.4. Planes</h3>

            <p>La PARTE CONTRATANTE puede optar por contratar los planes disponibles en el sitio web del CONTRATISTA.</p>

            <p>La PARTE CONTRATANTE declara estar al tanto de que la Sublicencia se otorga exclusivamente para ser utilizada en su infraestructura productiva y que la Plataforma Tecnológica no será instalada en la infraestructura de ningún tercero, incluidos sus clientes, sin el consentimiento previo por escrito del CONTRATISTA.</p>

            <p>Esta Sublicencia se otorga exclusivamente para ser utilizada por la persona jurídica perteneciente a la PARTE CONTRATANTE, tal como se registró en el proceso de admisión en línea o por el ejecutivo de ventas. Si va a ser utilizada por una persona jurídica diferente, es necesario contar con el consentimiento previo del CONTRATISTA.</p>

            <p>La Sublicencia otorga a la PARTE CONTRATANTE el derecho a utilizar libremente la Plataforma Tecnológica, de acuerdo con los términos y límites establecidos en estos Términos y Condiciones.</p>

            <h3>2.5. Prestación de Servicios</h3>

            <p>El CONTRATISTA puede optar por contratar los servicios de soporte y mantenimiento de la Plataforma Virtual como parte de la Sublicencia. Estos servicios no se aplican si la PARTE CONTRATANTE opta por el "plan de prueba de mensajería gratuita - versión de prueba".</p>

            <h3>3. PRUEBA – PERÍODO DE PRUEBA</h3>

            <h3>3.1. Prueba</h3>

            <p>Para evaluar mejor las ventajas de la Plataforma Tecnológica, la PARTE CONTRATANTE, al sublicenciar la Plataforma Tecnológica, puede seleccionar la opción "plan de prueba de mensajería gratuita", si está disponible. Bajo este plan, la PARTE CONTRATANTE recibirá un cierto número de mensajes, con el número disponible especificado en la opción seleccionada, y los mensajes pueden utilizarse hasta que se agote el crédito (el "Período de Prueba"). Los mensajes pueden utilizarse dentro de un período de 30, 60 o 90 días, a partir de la fecha de ejercicio de la opción de "plan de prueba de mensajería gratuita", finalizando el Período de Prueba a partir de la fecha en que los mensajes se hayan agotado o de la expiración del período de 30, 60 o 90 días, lo que ocurra primero.</p>

            <p><strong>3.2.</strong> Mientras se utilice la Plataforma Tecnológica, el CONTRATISTA enviará alertas / avisos a la PARTE CONTRATANTE informando sobre el número de mensajes aún disponibles durante el Período de Prueba. Una vez alcanzado el límite de mensajes disponibles, la Plataforma Tecnológica será bloqueada. La PARTE CONTRATANTE puede solicitar el desbloqueo de la plataforma contratando otro plan, pero no tendrá derecho a renovar el Período de Prueba.</p>

            <h3>3.3. Funciones</h3>

            <p>Dado que el Período de Prueba está destinado a la evaluación y experimentación de la Plataforma Tecnológica, la PARTE CONTRATANTE reconoce y acepta que no tendrá acceso a las funciones de dicha plataforma, las cuales estarán disponibles en el momento de contratar los planes en el sitio web del CONTRATISTA.</p>

            <h3>3.4. Soporte</h3>

            <p>Si la PARTE CONTRATANTE necesita soporte, puede utilizar los recursos disponibles en la plataforma, como el centro de ayuda, chat de soporte y videos. Pero si la PARTE CONTRATANTE desea que el CONTRATISTA brinde servicios de soporte o asistencia de manera personal a través de consultores, puede contratar dichos servicios por separado contactando al gerente de cuentas o enviando la solicitud a servicio@drw.com.ar.</p>

            <p><strong>3.5.</strong> La PARTE CONTRATANTE reconoce y acepta que no es posible extender el Período de Prueba, es decir, no se otorgarán nuevos mensajes gratuitos para evaluar la Plataforma Tecnológica, y el período de 90 (noventa) días para utilizar los mensajes proporcionados durante el Período de Prueba no será extendido. Una vez finalizado el Período de Prueba, y después de contratar un plan, la PARTE CONTRATANTE deberá realizar los pagos al CONTRATISTA según los términos indicados en la descripción del plan seleccionado.</p>

            <p><strong>3.6.</strong> Si la PARTE CONTRATANTE tiene la intención de continuar utilizando la Plataforma Tecnológica después de la expiración del Período de Prueba, deberá contactar al CONTRATISTA y/o seguir los procedimientos para incluir la información de su tarjeta para pagar por los servicios, de acuerdo con las instrucciones dadas en el sitio web del CONTRATISTA. Después del aviso correspondiente, el CONTRATISTA contactará a la PARTE CONTRATANTE para contratar efectivamente el plan.</p>

            <h3>4. PLANES</h3>

            <h3>4.1. Planes</h3>

            <p>Una vez registrado, la PARTE CONTRATANTE puede elegir entre los planes disponibles en el sitio web del CONTRATISTA.</p>

            <p><strong>4.2.</strong> Todos los planes mensuales se calculan según el volumen de USUARIOS DIARIOS bajo la siguiente definición: cada usuario externo de la plataforma se cuenta como USUARIO DIARIO cuando envía o recibe uno o más mensajes al o del CONTRATISTA, independientemente de si son respondidos por el CONTRATISTA con mensajes automatizados generados por bots, o por agentes en vivo, en un canal de conversación específico, durante el menor de los dos casos siguientes:</p>

            <h3>4.2.1. Caso 1</h3>

            <p>El tiempo transcurrido desde el primer mensaje, ya sea entrante o saliente, denominado INICIO DE SESIÓN, y un último mensaje ejecutado por un agente o por una regla automática configurada por el CONTRATISTA, denominado FIN DE SESIÓN; o</p>

            <h3>4.2.2. Caso 2</h3>

            <p>Durante un período continuo de 24 horas desde el primer mensaje enviado o recibido.</p>

            <h3>5. DURACIÓN Y TERMINACIÓN</h3>

            <h3>5.1. Duración</h3>

            <p>La terminación de estos Términos y Condiciones, por cualquier motivo, dará lugar a la interrupción inmediata de la Sublicencia de Uso de Software, así como la provisión de los Servicios.</p>

            <h3>5.2. Terminación</h3>

            <p>Cualquiera de las Partes puede dar por terminados estos Términos y Condiciones, sin estar sujeta a ninguna penalidad por ello, enviando un aviso previo a la otra Parte, si ésta última ha iniciado procedimientos de quiebra, bancarrota voluntaria, reestructuración judicial o extrajudicial o cualquier otro procedimiento legal de insolvencia y/o insolvencia financiera.</p>

            <h3>5.3. Terminación sin causa</h3>

            <p>Cualquiera de las Partes puede dar por terminados estos Términos y Condiciones, en cualquier momento, sin estar sujeta a ningún cargo o penalidad, después de haber presentado una solicitud de cancelación en la Plataforma Tecnológica.</p>

            <p><strong>5.3.1.</strong> Si la PARTE CONTRATANTE rescinde estos Términos y Condiciones entre el día 1 y el 15 del mes de contratación, la PARTE CONTRATANTE deberá pagar el mes completo y el CONTRATISTA prestará los servicios hasta el final de dicho mes.</p>

            <h3>5.4. Pagos de Servicios</h3>

            <p>Todos los Servicios prestados hasta la fecha de terminación deberán ser pagados, incluso si vencen después de dicha fecha de terminación. Lo mismo se aplica a todas las cantidades pendientes derivadas de la Sublicencia que son adeudadas, siempre que no se hayan pagado por completo hasta esa fecha.</p>

            <h3>6. PAGO</h3>

            <h3>6.1. Pago</h3>

            <p>El pago de la Sublicencia y la provisión de Servicios se realizará mediante tarjeta de crédito especificada por la PARTE CONTRATANTE, conforme a los términos indicados en el sitio web del CONTRATISTA o mediante transferencia bancaria, dependiendo del método disponible al momento de contratar los servicios.</p>

            <p><strong>6.2.</strong> Para iniciar el período de prueba o la suscripción al servicio, si la PARTE CONTRATANTE contrata uno de los planes para utilizar la Plataforma Tecnológica, deberá proporcionar la información de su tarjeta de crédito para que se debite de dicha tarjeta el monto correspondiente al plan y servicios relevantes y deberá realizar la primera transferencia bancaria para verificar adecuadamente la información de pago.</p>

            <p><strong>6.3.</strong> Si la PARTE CONTRATANTE no realiza el pago indicado anteriormente en el tiempo y la forma debida, deberá pagar una multa equivalente al 2% (dos por ciento) del monto pendiente, más la inflación calculada por las agencias correspondientes o cualquier otro índice que eventualmente lo reemplace, a partir de la fecha de vencimiento. En caso de terminación, se agregará una tasa de interés mensual del 1% (uno por ciento).</p>

            <h3>7. PROPIEDAD INTELECTUAL</h3>

            <h3>7.1. Propiedad Intelectual</h3>

            <p>La PARTE CONTRATANTE declara expresamente que: a) No está adquiriendo una licencia definitiva para la Plataforma Tecnológica, sino el derecho a utilizarla por el plazo indicado en la Sección 5 anterior; b) No tiene ningún derecho de propiedad intelectual sobre la Plataforma Tecnológica y la ejecución de estos Términos y Condiciones no transfiere ningún derecho de propiedad intelectual sobre dicha plataforma; c) Todos los derechos relacionados con el nombre, la marca, la patente, el dominio o cualquier otro asociado a la propiedad tecnológica de la Plataforma Tecnológica pertenecen al CONTRATISTA y/o a su licenciante DE ROSE & WHITE S.A.S conforme a los términos de los acuerdos celebrados entre ellos; y d) Bajo estos Términos y Condiciones, la PARTE CONTRATANTE tiene derecho a utilizar el número de licencias de la Plataforma Tecnológica indicado al momento del registro en el sitio web del CONTRATISTA. Si hay un aumento en el número de licencias o usuarios de la Plataforma Tecnológica, dichas nuevas licencias o usuarios serán cobrados al mismo precio que se cobra al CONTRATISTA, a partir de la fecha en que aumentó el número de licencias.</p>

            <h3>7.2. Uso indebido</h3>

            <p>El CONTRATISTA no será responsable, ni directa ni indirectamente, ante la PARTE CONTRATANTE y/o terceros por el uso indebido de la Plataforma Tecnológica y de cualquier patente, marca registrada o derechos de propiedad. Asimismo, el CONTRATISTA es responsable de las consecuencias intencionales o no intencionales que el uso indebido de la Plataforma Tecnológica pueda causar, incluidos, entre otros, los siguientes usos indebidos: hacking, errores de código o servicios introducidos por el CONTRATISTA, llamadas excesivas a la API que causen retrasos en la infraestructura de la PARTE CONTRATANTE o costos adicionales, uso de código con bucles infinitos, casos de uso no permitidos por la ley, casos de uso no permitidos por políticas de canales conectados, y cualquier otro uso indebido que viole el funcionamiento adecuado, la ley, el sentido común y las buenas costumbres.</p>

            <h3>7.3. Derechos</h3>

            <p>El CONTRATISTA es el propietario de los derechos de autor y derechos de propiedad intelectual sobre las rutinas informáticas, procesos de desarrollo y cualquier componente de los mismos y puede utilizarlos libremente en la creación de otros productos, así como licenciarlos a terceros sin la aprobación previa de la PARTE CONTRATANTE. La PARTE CONTRATANTE no modificará las características de la Plataforma Tecnológica, ni la ampliará o cambiará sin el consentimiento previo del CONTRATISTA. Cualquier modificación a la Plataforma Tecnológica acordada por las Partes será realizada únicamente por el CONTRATISTA o una persona expresamente autorizada por él. La PARTE CONTRATANTE no transferirá, en todo o en parte, cederá, arrendará, pignorará, donará o de otra manera dispondrá de la Sublicencia, de manera temporal o permanente, ni de sus manuales ni de cualquier información relacionada sin el consentimiento previo por escrito del CONTRATISTA. Si la PARTE CONTRATANTE incumple cualquiera de las obligaciones previamente indicadas o de alguna manera dispone de los derechos de propiedad intelectual sobre la Plataforma Tecnológica, el CONTRATISTA tendrá derecho a reclamar una compensación. Todos los derechos no expresamente otorgados a la PARTE CONTRATANTE permanecerán con DE ROSE & WHITE S.A.S. y el CONTRATISTA. DE ROSE & WHITE S.A.S. y/o el CONTRATISTA tendrán el derecho de realizar cualquier cambio, modificación y mejora a los servicios de manera regular. Si dicha mejora o modificación es indispensable para operar los Servicios, DE ROSE & WHITE S.A.S. y/o el CONTRATISTA notificará a la PARTE CONTRATANTE en consecuencia.</p>

            <h3>7.4. Servicios Profesionales</h3>

            <p>Al momento del registro, la PARTE CONTRATANTE deberá revisar la descripción de los servicios indicados en el plan contratado. Si el servicio requerido no está dentro del plan, la PARTE CONTRATANTE puede contratarlo seleccionando la opción correcta al momento del registro mientras estos Términos y Condiciones estén vigentes, al cambiar el plan en el sitio web del CONTRATISTA o contactando al ejecutivo de ventas del CONTRATISTA para contratar el servicio específico.</p>

            <p><strong>7.5.</strong> Los servicios prestados por el CONTRATISTA incluyen: soporte, mantenimiento, implementación, capacitación y consultoría (“Servicios Profesionales”). Además de cualquier disposición de cualquier otro acuerdo entre las Partes, se aplicarán los siguientes términos: (i) el CONTRATISTA conservará todos los derechos sobre los entregables, excluyendo cualquier tecnología o material previo proporcionado por la PARTE CONTRATANTE para ser incorporado en dicho entregable; (ii) el CONTRATISTA otorgará a la PARTE CONTRATANTE una sublicencia global no exclusiva e intransferible, libre de regalías, para utilizar cualquier entregable, lo que permite a la PARTE CONTRATANTE utilizar los entregables en cumplimiento con los Servicios, durante el Plazo de Uso; y (iii) la PARTE CONTRATANTE reconoce que nada de lo aquí expuesto restringirá o impedirá que el CONTRATISTA preste servicios similares a un tercero.</p>

            <p><strong>7.6.</strong> Para proporcionar servicios de soporte y mantenimiento, el CONTRATISTA mantendrá contacto con la PARTE CONTRATANTE.</p>

            <h3>8. RESPONSABILIDAD</h3>

            <h3>8.1. Obligaciones de la PARTE CONTRATANTE</h3>

            <p>La PARTE CONTRATANTE deberá (i) informar al CONTRATISTA cualquier irregularidad detectada en la Plataforma Tecnológica para que se implementen las correcciones necesarias lo antes posible; (ii) facilitar los recursos materiales y humanos necesarios para la instalación de la Plataforma Tecnológica por parte del CONTRATISTA dentro de las horas laborales habituales del CONTRATISTA y en la fecha previamente acordada por las partes; (iii) mantener personal capacitado para operar la Plataforma Tecnológica; y (iv) utilizar la Plataforma Tecnológica de acuerdo con las instrucciones del CONTRATISTA. La PARTE CONTRATANTE será responsable de cualquier actividad en las cuentas de usuario de la PARTE CONTRATANTE y acepta cumplir con todas las leyes y regulaciones aplicables relacionadas con la protección de datos personales, comunicaciones internacionales, transmisión de datos técnicos o personales, regulaciones y leyes de control de exportación y datos. La PARTE CONTRATANTE deberá notificar de inmediato al CONTRATISTA sobre cualquier uso no autorizado de cualquier contraseña o cuenta o cualquier violación real o amenazada de la seguridad en relación con los Servicios.</p>

            <h3>8.2. Obligaciones del CONTRATISTA</h3>

            <p>El CONTRATISTA deberá proteger la seguridad e integridad de los Servicios y deberá cumplir con todas las leyes y regulaciones aplicables en [la República Argentina], relacionadas con la prestación de Servicios, protección de datos personales y transmisión de información técnica o personal. El programa de seguridad de datos del CONTRATISTA incluirá medidas técnicas, organizativas y de seguridad razonables y efectivas contra la destrucción, pérdida, no disponibilidad, acceso no autorizado o alteración de los datos personales de la PARTE CONTRATANTE, o de terceros que estén en posesión o bajo control del CONTRATISTA (los "Controles"). El CONTRATISTA no es el propietario de ningún dato de la PARTE CONTRATANTE. La PARTE CONTRATANTE será la única responsable de la exactitud, calidad, integridad y legalidad de sus datos y de la protección de la propiedad intelectual relacionada con dichos datos, así como del derecho a utilizarlos. El CONTRATISTA no será responsable de la eliminación, corrección, destrucción, daño o pérdida de los datos de la PARTE CONTRATANTE que no surjan de la culpa del CONTRATISTA. El uso de los datos de la PARTE CONTRATANTE por parte del CONTRATISTA se limitará a la prestación de los Servicios a la PARTE CONTRATANTE y con el propósito de que el CONTRATISTA pueda cumplir con las obligaciones establecidas en el presente documento.</p>

            <h3>9. PROTECCIÓN DE DATOS Y CONFIDENCIALIDAD</h3>

            <h3>9.1. Protección de Datos</h3>

            <p>La PARTE CONTRATANTE declara que conoce, acepta y cumplirá con todas las leyes, decretos, reglamentos y cualquier otra norma existente sobre protección de datos, relacionadas con cualquier dato almacenado, archivado, compilado, procesado, recibido, accedido y/o obtenido por cualquier medio.</p>

            <h3>9.2. Consentimiento y Responsabilidad</h3>

            <p>El CONTRATISTA declara que, conforme a las leyes de Protección de Datos Personales, reconoce la necesidad de obtener el consentimiento por escrito de sus clientes/usuarios para tratar datos personales a través de la Plataforma Tecnológica. Dicho consentimiento deberá ser obtenido exclusivamente por la PARTE CONTRATANTE, eximiendo al CONTRATISTA de cualquier responsabilidad relacionada con los términos de dichas leyes.</p>

            <h3>9.3. Consentimiento Expreso</h3>

            <p>El consentimiento deberá ser solicitado expresamente por la PARTE CONTRATANTE. Además, deberá informar al cliente/usuario dónde se utilizan los datos personales, el propósito de su uso, el período de uso y quién tendrá acceso a dichos datos. La PARTE CONTRATANTE se compromete a proporcionar al cliente/usuario los formularios necesarios para modificar, eliminar o corregir sus datos personales, incluida, sin limitación, la opción de no recibir ningún mensaje/aviso de la PARTE CONTRATANTE.</p>

            <h3>9.4. Protección de Datos Personales</h3>

            <p>El CONTRATISTA declara que protegerá los datos personales del titular, así como sus derechos a la privacidad, la protección de datos personales y la confidencialidad de las comunicaciones y registros privados, conforme a las leyes argentinas. Asimismo, adoptará los procedimientos técnicos necesarios para garantizar el cumplimiento de dichas obligaciones legales. Además, está prohibido compartir con terceros cualquier dato personal recibido, excepto si lo exige la ley o si está expresamente autorizado por la PARTE CONTRATANTE.</p>

            <h3>9.5. Requerimientos de Autoridades</h3>

            <p>En el caso de un requerimiento administrativo o extrajudicial de una autoridad reguladora o gubernamental que exija aclaraciones sobre el comportamiento de la PARTE CONTRATANTE y los datos incluidos en la Plataforma Tecnológica, el CONTRATISTA autorizará la violación de la obligación de confidencialidad relacionada con las disposiciones establecidas en el presente documento, y se compromete a mantener cualquier dato personal confidencial, excepto si lo exige un tribunal a través de una orden judicial.</p>

            <h3>9.6. Confidencialidad</h3>

            <p>Las partes reconocen que, en virtud de este Acuerdo, tendrán acceso a información confidencial, procedimientos, documentos u otros datos. Las partes acuerdan que cualquier información, procedimiento, documento y/o otros datos propiedad de una parte y que sean proporcionados a la otra parte, se mantendrán confidenciales y bajo ninguna circunstancia serán divulgados, ya sea en su totalidad o en parte, sin el consentimiento por escrito del propietario de dicha información y/o documento.</p>

            <h3>10. TÉRMINOS GENERALES</h3>

            <h3>10.1. Representaciones y declaraciones</h3>

            <p>Cada representante de las partes garantiza que tiene el poder legal y la autoridad necesarios para ejecutar estos Términos y Condiciones. El CONTRATISTA declara y garantiza que: (a) los Servicios se realizarán de acuerdo con la información del producto disponible en www.drw.com.ar (el "Sitio Web"), conforme a estos Términos y Condiciones y en cumplimiento con la descripción del plan contratado, en condiciones normales; (b) utilizará los medios técnicos razonables para garantizar que los Servicios proporcionados a la PARTE CONTRATANTE no incluyan ningún dispositivo de desactivación, virus, troyanos, "huevos de pascua", "puertas trampa", "puertas traseras", "cancelbots" u otras rutinas de programación para dañar, interceptar o tomar cualquier software o datos pertenecientes a la PARTE CONTRATANTE. El incumplimiento de esta garantía obligará al CONTRATISTA a hacer todo lo posible para subsanar dicha violación. Si no se puede subsanar dentro de los 30 (treinta) días posteriores a la notificación por escrito enviada por la PARTE CONTRATANTE, ésta puede rescindir estos Términos y Condiciones y ser reembolsada por los montos pagados.</p>

            <h3>10.2. Exención de garantías adicionales</h3>

            <p>Aparte de las declaraciones y garantías aquí establecidas, el CONTRATISTA no hace ninguna representación o garantía adicional, ya sea expresa, implícita o legal, independientemente de su origen. El CONTRATISTA renuncia expresamente a todas las garantías implícitas de comercialización y adecuación para un propósito específico. El CONTRATISTA no garantiza que los servicios estén libres de errores, que cumplan con todos los requisitos de la PARTE CONTRATANTE o que sean adecuados o seguros. La PARTE CONTRATANTE no hará ninguna representación o garantía en nombre del CONTRATISTA a terceros.</p>

            <h3>10.3. Limitaciones de red</h3>

            <p>Los servicios del CONTRATISTA pueden estar sujetos a restricciones, demoras y otros inconvenientes inherentes al uso de los servicios proporcionados por terceros como Facebook, Messenger, WhatsApp, etc., o inconvenientes derivados del uso de Internet y/o comunicaciones electrónicas. El CONTRATISTA no es responsable de ninguna falla o demora en la entrega ni de ningún otro daño derivado de dichos inconvenientes que no sean causados exclusivamente por la culpa del CONTRATISTA.</p>

            <h3>10.4. Limitación de responsabilidad</h3>

            <p>La responsabilidad total del CONTRATISTA derivada de o relacionada con estos Términos y Condiciones, estará limitada a los montos realmente pagados y/o adeudados por la PARTE CONTRATANTE, durante el período de 12 (doce) meses anteriores al evento que originó dicha responsabilidad. En ningún caso el CONTRATISTA y/o sus licenciantes serán responsables de daños indirectos, futuros, inciertos u otros o de la pérdida de beneficios, pérdida de oportunidad, pérdida de ingresos, ganancias, uso u otros similares, derivados de o de alguna manera relacionados con estos Términos y Condiciones, incluso si la parte o los licenciantes de dicha parte han sido previamente notificados de la posibilidad de tales daños.</p>

            <h3>10.5. Marketing</h3>

            <p>La PARTE CONTRATANTE autoriza al CONTRATISTA a utilizar el nombre, marca y logotipo de la PARTE CONTRATANTE en el sitio web del CONTRATISTA, en los materiales de marketing y comunicación del CONTRATISTA y para identificar a la PARTE CONTRATANTE como cliente/socio del CONTRATISTA.</p>

            <h3>10.6. Modificaciones</h3>

            <p>El CONTRATISTA puede realizar cualquier cambio e incluir cualquier adenda y anexos a estos Términos y Condiciones, dando al menos un aviso de 30 días a la PARTE CONTRATANTE. La falta de objeción de la PARTE CONTRATANTE a cualquier nueva condición dentro de los 10 (diez) días posteriores a dicho aviso se interpretará, por aplicación de la ley, como si la PARTE CONTRATANTE hubiera aceptado completamente las nuevas condiciones. Sin embargo, si la PARTE CONTRATANTE no está de acuerdo con las nuevas condiciones, puede rescindir inmediatamente estos Términos y Condiciones y deberá pagar por los servicios prestados hasta la fecha de terminación efectiva.</p>

            <h3>10.7. Cesión</h3>

            <p>Ninguna de las partes cederá estos Términos y Condiciones a ningún tercero, a menos que cuente con el consentimiento previo por escrito de la otra parte.</p>

            <h3>10.8. Fuerza mayor</h3>

            <p>Ninguna de las partes será responsable por ningún incumplimiento o demora en el cumplimiento de las obligaciones bajo estos Términos y Condiciones en la medida en que dicho incumplimiento o demora haya sido causado por eventos fuera del control de dicha parte. Tales eventos incluirán, entre otros, actos de Dios o del enemigo público, actos del Estado o actos soberanos, incendios, inundaciones, huelgas, disturbios civiles o similares.</p>

            <h3>10.9. Relación</h3>

            <p>La relación entre la PARTE CONTRATANTE y el CONTRATISTA no será la de una sociedad, empresa comercial u operativa, empresa conjunta o agencia en relación con la ejecución de estos Términos y Condiciones o como resultado del uso de los Servicios.</p>

            <h3>10.10. Conflictos, ley aplicable, jurisdicción</h3>

            <p>Estos Términos y Condiciones se regirán por las leyes de [Argentina]. Las partes acuerdan someterse a la jurisdicción de los [tribunales ordinarios de la ciudad de Córdoba], renunciando expresamente a cualquier otra jurisdicción, siendo dichos tribunales la única autoridad para resolver cualquier conflicto o controversia que surja de la interpretación o ejecución de estos Términos y Condiciones.</p>

            <p>10 de agosto de 2024</p>

            <p>DE ROSE & WHITE S.A.S</p>
          
        </div>
      </div>
    </main>
  );
}

export default TermsAndConditions;